import * as React from 'react';
import Layout from '../components/Layout';
import Seo from '../components/Seo';

const NotFoundPage = () => (
	<Layout>
		<Seo title="Error 404 page not found." description="Error 404 page not found." />
		<div className="o-wrapper" style={{ minHeight: 'calc(100vh - 250px)' }}>
			<h1 style={{ textAlign: 'center' }}>NOT FOUND</h1>
			<p style={{ textAlign: 'center' }}>You just hit a route that doesn't exist... the sadness.</p>
		</div>
	</Layout>
);

export default NotFoundPage;
